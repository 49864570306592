<template>
  <SurveysTable
    :surveys="surveys"
    :companies="companies"
    :loading="loading"
    :total="total"
    @pagination="handlePagination"
  >
    <template #topActions>
      <VBtn
        color="primary"
        :to="{ name : Names.R_ENGAGEMENT_SURVEYS_CREATE }"
      >
        <VIcon left>
          fal fa-plus
        </VIcon>

        Новый опрос
      </VBtn>
    </template>

    <template #rowActions="{ item }">
      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            dark
            color="blue"
            class="mx-1"
            :to="{ name : Names.R_ENGAGEMENT_SURVEYS_VIEW, params : { surveyId : item.id } }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-eye
            </VIcon>
          </VBtn>
        </template>

        <span>Просмотр</span>
      </VTooltip>
    </template>
  </SurveysTable>
</template>

<script>
import SurveysTable from './SurveysTable.vue';

export default {
  name: 'SurveysTableContainer',

  components: {
    SurveysTable,
  },

  inject: ['Names'],

  data() {
    return {
      surveys: [],
      loading: false,
      companies: [],
      pagination: {
        page: 0,
        limit: 10,
      },
      total: 0,
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;
        const { pagination: data } = this;

        const [companiesResponse, surveysResponse] = await Promise.all([
          this.$di.api.Account.indexCompany(),
          this.$di.api.Engagement.surveysIndex(data),
        ]);

        const {
          surveys = [], page, limit, total,
        } = surveysResponse;

        this.surveys = surveys;
        this.companies = companiesResponse.companies || [];
        this.pagination = { page, limit };
        this.total = total;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async handlePagination({ page, itemsPerPage, itemsLength }) {
      try {
        this.loading = true;

        const limit = itemsPerPage === -1 ? itemsLength : itemsPerPage;

        this.pagination = { page, limit };

        const data = this.pagination;

        const surveysIndexResponse = await this.$di.api.Engagement.surveysIndex(data);

        this.surveys = surveysIndexResponse.surveys || [];
        this.total = surveysIndexResponse.total || 0;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
