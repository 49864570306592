var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VDataTable',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.surveys,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.total},on:{"update:options":function($event){_vm.options=$event},"pagination":function($event){return _vm.$emit('pagination', $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('VToolbar',{attrs:{"flat":""}},[_c('VToolbarTitle',[_vm._v("Опросы")]),_c('VSpacer'),_vm._t("topActions")],2)]},proxy:true},{key:"item.companyId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.companyName(item))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'draft')?_c('VTooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VIcon',_vm._g(_vm._b({attrs:{"color":"tt-black lighten-3"}},'VIcon',attrs,false),on),[_vm._v(" fal fa-file ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.status))])]):(item.status === 'published')?_c('VTooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VIcon',_vm._g(_vm._b({attrs:{"color":"tt-yellow"}},'VIcon',attrs,false),on),[_vm._v(" fal fa-file-alt ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.status))])]):(item.status === 'active')?_c('VTooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VIcon',_vm._g(_vm._b({attrs:{"color":"tt-green"}},'VIcon',attrs,false),on),[_vm._v(" fal fa-file-alt ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.status))])]):(item.status === 'finished')?_c('VTooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VIcon',_vm._g(_vm._b({attrs:{"color":"tt-black"}},'VIcon',attrs,false),on),[_vm._v(" fal fa-file-check ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.status))])]):_c('VTooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VIcon',_vm._g(_vm._b({attrs:{"color":"tt-blue"}},'VIcon',attrs,false),on),[_vm._v(" fal fa-question ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.status))])])]}},{key:"item.startAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertStartAt(item.startAt))+" ")]}},{key:"item.finishAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertFinishAt(item.finishAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_vm._t("rowActions",null,{"item":item})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }