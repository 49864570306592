<template>
  <TTView>
    <VRow>
      <VCol>
        <SurveysTableContainer />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import SurveysTableContainer from '../components/SurveysTableContainer.vue';

export default {
  name: 'Surveys',

  components: {
    SurveysTableContainer,
  },
};
</script>
