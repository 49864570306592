<template>
  <VDataTable
    class="elevation-1"
    :headers="headers"
    :items="surveys"
    :loading="loading"
    :options.sync="options"
    :server-items-length="total"
    @pagination="$emit('pagination', $event)"
  >
    <template #top>
      <VToolbar flat>
        <VToolbarTitle>Опросы</VToolbarTitle>

        <VSpacer />

        <slot name="topActions" />
      </VToolbar>
    </template>

    <template #item.companyId="{ item }">
      {{ companyName(item) }}
    </template>

    <template #item.status="{ item }">
      <VTooltip
        v-if="item.status === 'draft'"
        top
      >
        <template #activator="{ on, attrs }">
          <VIcon
            v-bind="attrs"
            color="tt-black lighten-3"
            v-on="on"
          >
            fal fa-file
          </VIcon>
        </template>

        <span>{{ item.status }}</span>
      </VTooltip>

      <VTooltip
        v-else-if="item.status === 'published'"
        top
      >
        <template #activator="{ on, attrs }">
          <VIcon
            v-bind="attrs"
            color="tt-yellow"
            v-on="on"
          >
            fal fa-file-alt
          </VIcon>
        </template>

        <span>{{ item.status }}</span>
      </VTooltip>

      <VTooltip
        v-else-if="item.status === 'active'"
        top
      >
        <template #activator="{ on, attrs }">
          <VIcon
            v-bind="attrs"
            color="tt-green"
            v-on="on"
          >
            fal fa-file-alt
          </VIcon>
        </template>

        <span>{{ item.status }}</span>
      </VTooltip>

      <VTooltip
        v-else-if="item.status === 'finished'"
        top
      >
        <template #activator="{ on, attrs }">
          <VIcon
            v-bind="attrs"
            color="tt-black"
            v-on="on"
          >
            fal fa-file-check
          </VIcon>
        </template>

        <span>{{ item.status }}</span>
      </VTooltip>

      <VTooltip
        v-else
        top
      >
        <template #activator="{ on, attrs }">
          <VIcon
            v-bind="attrs"
            color="tt-blue"
            v-on="on"
          >
            fal fa-question
          </VIcon>
        </template>

        <span>{{ item.status }}</span>
      </VTooltip>
    </template>

    <template #item.startAt="{ item }">
      {{ convertStartAt(item.startAt) }}
    </template>

    <template #item.finishAt="{ item }">
      {{ convertFinishAt(item.finishAt) }}
    </template>

    <template #item.actions="{ item }">
      <slot
        name="rowActions"
        :item="item"
      />
    </template>
  </VDataTable>
</template>

<script>
export default {
  name: 'SurveysTable',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    surveys: {
      type: Array,
      required: true,
    },

    companies: {
      type: Array,
      required: true,
    },

    total: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      headers: [
        {
          text: 'Имя',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Компания',
          align: 'left',
          sortable: false,
          value: 'companyId',
        },
        {
          text: 'Slug',
          align: 'left',
          sortable: false,
          value: 'slug',
        },
        {
          text: 'Статус',
          align: 'left',
          sortable: false,
          value: 'status',
        },
        {
          text: 'Начало',
          align: 'left',
          sortable: false,
          value: 'startAt',
          width: '15%',
        },
        {
          text: 'Конец',
          align: 'left',
          sortable: false,
          value: 'finishAt',
          width: '15%',
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'actions',
        },
      ],
      options: {},
    };
  },

  methods: {
    convertStartAt(value) {
      return this.$dayjs(new Date(value)).format('HH:mm (DD-MM-YYYY)');
    },

    convertFinishAt(value) {
      return this.$dayjs(new Date(value)).format('HH:mm (DD-MM-YYYY)');
    },
    companyName(item) {
      const company = this.companies.find((c) => c.id === item.companyId);

      if (company) return company.name;

      return '';
    },
  },
};
</script>
